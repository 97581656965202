import './Home.css';

function Home() {
  return (
    <div className="App">
      <div className='content'>
        <h1>
          Welcome here_
        </h1>
        <h2>
          I'm Sunil G.
        </h2>
        <p>
          <span>A Car & Tech</span>
          <span>Enthusiast</span>
        </p>
        <p className="description">
          <span>
            My name is Sunil GOULAMHOUSSEN, I'm 23, and I currently work as a Cloud Engineering Analyst at Deloitte Luxembourg. I recently graduated from an engineering school near La Défense, Paris, where I specialized in Fintech. I'm passionate about web technologies and especially the evolution of the web into a more respectful, decentralized, and interactive space, such as Web3.
          </span>
          <span className="notel">
            I aspire to make our digital world a better place by addressing the challenges of our most widespread activity—surfing the web. While it has revolutionized our way of living and social interactions, it still poses issues like dependency, overconsumption, and the ever-growing concern of personal data theft.
          </span>
          <span>
            Feel free to check out my <a href="../studies">studies</a> or my <a href="../resume">resume</a> to learn more about my background and experience.
          </span>
          <span>
            If you have any questions, don't hesitate to contact me via the <a href="../contact">contact page</a>!
          </span>
        </p>
      </div>
      <div className='list'>
        <div className='list-item'>
          <h3>Cloud Engineering</h3>
          <p className='list-subtitle'>Deloitte Luxembourg</p>
          <div className='list-content'>
            I'm working as a Cloud Engineering Analyst at Deloitte Luxembourg. I'm working on Cloud projects for Luxembourgish clients.
            <br></br>
            <div className='list-skills'>
              <span>Azure</span>
              <span>Python</span>
              <span>SQL</span>
              <span>Dataiku</span>
              <span>Snowflake</span>
            </div>
            <a href="https://www.deloitte.com/lu/en.html" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Web3 Hackathon</h3>
          <p className='list-subtitle'>ETH Warsaw</p>
          <div className='list-content'>
            We created with my team a platform that secures companies' invoices with blockchain. 3rd prize of Request Network Sponsor.
            <br></br>
            <div className='list-skills'>
              <span>React</span>
              <span>Web3Auth</span>
              <span>API</span>
              <span>Synaps KYB</span>
            </div>
            <a href="https://web.sunil.fr/invoic3/" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Web3 internship</h3>
          <p className='list-subtitle'>Horae</p>
          <div className='list-content'>
            Watch certification through blockchain for users and brands.
            <br></br>
            <div className='list-skills'>
              <span>React</span>
              <span>wagmi</span>
              <span>ethers.js</span>
            </div>
            <a href="https://www.horae.io" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Web3 Hackathon</h3>
          <p className='list-subtitle'>Starton</p>
          <div className='list-content'>
            We created with my team a website that can create and manage a numerical passport for cars so that there is no more possibility to falsify the odometer or other car data.
            <br></br>
            <div className='list-skills'>
              <span>React</span>
              <span>web3.js</span>
            </div>
            <a href="https://idcar.lesminions.com" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Website creation</h3>
          <p className='list-subtitle'>La Plume De Vinci</p>
          <div className='list-content'>
            I created a dynamic website with database integration and admin website. It is used to produce and show articles and writtings of the association members.
            <br></br>
            <div className='list-skills'>
              <span>ReactJS</span>
              <span>Airtable API</span>
            </div>
            <a href="https://laplumedevinci.lesminions.com/" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Website / SEO management</h3>
          <p className='list-subtitle'>Vinci Eco Drive</p>
          <div className='list-content'>
            I participated at the website transition and managed the SEO ranking of the new website.
            <br></br>
            <div className='list-skills'>
              <span>SEO</span>
              <span>HTML</span>
              <span>React</span>
            </div>
            <a href="https://vinciecodrive.fr" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Blockchain Development</h3>
          <p className='list-subtitle'>Kryptosphere</p>
          <div className='list-content'>
            I'm improving my blockchain development skills by practicing with others in Solidity, listening to workshops and many other activities. Thanks to Kryptosphere for all this support.
            <br></br>
            <div className='list-skills'>
              <span>Solidity</span>
              <span>React</span>
              <span>Foundry</span>
            </div>
            <a href="https://kryptosphere.org" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
        <div className='list-item'>
          <h3>Web3 Business Hackathon</h3>
          <p className='list-subtitle'>iExec</p>
          <div className='list-content'>
            With one teammate we established a Business model to develop fictively a NFT market place with visited monuments certification for example.
            <br></br>
            <div className='list-skills'>
              <span>Culture Web3</span>
              <span>Project Managment</span>
              <span>Tokenization</span>
            </div>
            <a href="https://iexec.io" target="_blank" rel="noreferrer">
              Browse website
            </a>
          </div>
        </div>
      </div>
      <article class="user">
        <dl class="user-data">
          <dt>Avatar:</dt>
          <dd class="user-avatar">
            <img src="https://avatars.githubusercontent.com/u/74963340?v=4" alt="github_profile" />
          </dd>
          <dt>Fullname:</dt>
          <dd class="user-name"></dd>
          <dt>Account:</dt>
          <a class="user-github-url" href="https://github.com/SnyDrive25"><dd class="user-account">SnyDrive25</dd></a>
        </dl>
        <dl class="user-stats">
          <dt>Repos</dt>
          <a class="user-repos-url" href="https://github.com/SnyDrive25?tab=repositories"><dd class="user-repos" data-stats="repos">17</dd></a>
        </dl>
      </article>
    </div>
  );
}

export default Home;
