import './Contact.css';
import '../../App.css';
import me from '../../images/sunil.png';
import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';

function Contact() {
  const form = useRef();
  const [modalMessage, setModalMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      to_name: 'Sunil',
      from_name: e.target.user_name.value,
      message: e.target.message.value,
      from_email: e.target.user_email.value,
    };

    emailjs
      .sendForm('service_hxhh5tq', 'template_tegdo3s', form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY, templateParams)
      .then(
        () => {
          setModalMessage('Your message has been successfully sent!');
          setIsModalVisible(true);
        },
        (error) => {
          setModalMessage('Failed to send the message. Please try again.');
          setIsModalVisible(true);
        },
      );
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="page">
      <div className='me'>
        <img src={me} alt="sunil"></img>
      </div>
      <div className="social-btns">
        <a href="https://www.twitter.com/snydrive" target="_blank" rel='noreferrer'><i className="fa fa-twitter"></i></a>
        <a href="https://www.linkedin.com/in/sunil-goulamhoussen/" target="_blank" rel='noreferrer'><i className="fa fa-linkedin"></i></a>
        <a href="mailto:sunilgouvi@gmail.com" target="_blank" rel='noreferrer'><i className="fa fa-envelope"></i></a>
      </div>
      <div className='contact'>
        <p id="reponse">Let's get in touch !</p>
        <p id="ligne">Feel free to send me a little message, I'll answer quickly !</p>
        <p id="aaa">If I don't, feel free to send me an email at <a href="mailto:sunilgouvi@gmail.com" target="_blank" rel="noreferrer">sunilgouvi@gmail.com</a></p>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label htmlFor="name">Name</label>
            <input type="text" id="user_name" name="name" required />
          </div>
          <div>
            <label htmlFor="user_email">Email</label>
            <input type="email" id="user_email" name="email" required />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" />
          </div>
          <input type="submit" value="Send" />
        </form>
      </div>

      {isModalVisible && (
        <div className="modal">
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <p>{modalMessage}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;